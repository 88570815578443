<script setup lang="ts">
const { isManager } = useAuth()

const { data } = await useWorkspaceSettings()
const hasUnhandledThreats = computed(() => {
  return Boolean(data.value?.unhandled_threats_count)
})

const route = useRoute()
const integrationsIsActive = computed(() => {
  return route.path.startsWith("/settings/integrations")
})
</script>
<template>
  <div data-slot="section" class="flex flex-col gap-0.5">
    <SidebarNavigationItem to="/dashboard" :text="$t('navigation.dashboard')">
      <SvgoPikaDashboard />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="$hasFeature('app-simulations')"
      to="/simulations"
      :text="$t('navigation.simulations.index')"
    >
      <SvgoPikaSimulation />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="$hasFeature('app-training')"
      to="/elearning"
      :text="$t('navigation.training')"
    >
      <SvgoPikaTraining />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="$hasFeature('app-policies')"
      to="/policies"
      :text="$t('navigation.policies')"
    >
      <SvgoPikaFile />
    </SidebarNavigationItem>

    <SidebarNavigationItem
      v-if="$hasFeature('app-automation')"
      to="/automation"
      :text="$t('navigation.automation.index')"
    >
      <SvgoPikaAutomation />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="$hasFeature('app-threats')"
      to="/threats"
      :text="$t('navigation.threats')"
      :show-badge="hasUnhandledThreats"
    >
      <SvgoPikaThreat />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="$hasFeature('app-breaches')"
      to="/breaches"
      :text="$t('navigation.breaches')"
    >
      <SvgoPikaBreach />
    </SidebarNavigationItem>
  </div>
  <div
    data-slot="section"
    class="flex flex-col gap-0.5 @[12rem]/navigation:mt-8"
  >
    <h3
      class="mb-1 hidden px-2 text-xs/6 font-medium text-slate-500 @[12rem]/navigation:inline-block"
    >
      {{ $t("navigation.configuration") }}
    </h3>
    <SidebarNavigationItem to="/team" :text="$t('navigation.team')">
      <SvgoPikaUser />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="isManager"
      to="/settings/integrations"
      :text="$t('navigation.integrations')"
    >
      <SvgoPikaIntegration />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      v-if="isManager"
      to="/settings"
      :text="$t('navigation.settings.index')"
      :disable-active-indicator="integrationsIsActive"
    >
      <SvgoPikaSettings />
    </SidebarNavigationItem>
  </div>
  <div aria-hidden="true" class="mt-8 flex-1" />
</template>
