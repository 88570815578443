<script setup lang="ts">
const open = ref(false)

useHead({
  bodyAttrs: {
    class: computed(() => {
      if (open.value) {
        return "overflow-hidden"
      }
      return ""
    }),
  },
})

const dialog = useTemplateRef("dialog")
function openNavigation() {
  open.value = true
}
function closeNavigation() {
  open.value = false
}

const route = useRoute()

watch(
  () => route.path,
  () => {
    closeNavigation()
  },
)

watch(
  () => open.value,
  () => {
    if (open.value) {
      dialog.value?.showModal()
    } else {
      dialog.value?.close()
    }
  },
)

/** Closes dialog when clicking on backdrop */
function dialogClick(event: Event) {
  // If you click on element inside the Dialog, nodename will be that element.
  // However if you click outside the dialog, like the backdrop, the nodename will be DIALOG
  // See https://www.stefanjudis.com/blog/a-look-at-the-dialog-elements-super-powers#how-to-close-the-modal-on-%60%3A%3Abackdrop%60-click
  if (
    event.target instanceof HTMLElement &&
    event.target.nodeName === "DIALOG"
  ) {
    closeNavigation()
  }
}
</script>
<template>
  <header class="flex items-center justify-between px-4 lg:hidden">
    <NuxtLink to="/dashboard" class="relative">
      <LogoSvg class="size-8" />
    </NuxtLink>

    <div class="-ml-2 py-2.5">
      <button
        class="relative flex min-w-0 items-center gap-3 rounded-lg p-2 text-left text-base/6 font-medium text-slate-950 *:data-[slot=icon]:size-6 *:data-[slot=icon]:shrink-0 *:data-[slot=icon]:fill-slate-500 data-active:*:data-[slot=icon]:fill-slate-950 data-hover:*:data-[slot=icon]:fill-slate-950"
        type="button"
        @click="openNavigation"
      >
        <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true">
          <path
            d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z"
          />
        </svg>
      </button>
    </div>
  </header>
  <DialogWrapper v-model:open="open">
    <dialog
      ref="dialog"
      :open
      class="@container/navigation fixed inset-2 m-0 h-full max-h-[98svh] w-4/5 flex-col rounded-lg bg-white p-2 ring-3 ring-slate-200"
      @click="dialogClick"
    >
      <nav class="flex h-full flex-col">
        <div class="flex justify-between border-b border-slate-950/5 p-4">
          <NuxtLink to="/dashboard" class="relative">
            <LogoSvg class="size-8" />
          </NuxtLink>
          <button autofocus @click="closeNavigation">
            <SvgoPikaClose class="size-7 text-slate-500" />
          </button>
        </div>
        <div class="flex flex-1 flex-col overflow-y-auto p-4">
          <SidebarNavigationItems />
          <SidebarLocaleSwitcher class="w-full" />
        </div>
        <div class="flex flex-col gap-1 border-t border-slate-950/5 p-4">
          <SidebarNavigationItem to="/logout" :text="$t('navigation.sign_out')">
            <SvgoPikaLogout />
          </SidebarNavigationItem>
        </div>
      </nav>
    </dialog>
  </DialogWrapper>
</template>
